<style>
/* .show .dropdown-toggle:after { transform:none !important; } */
</style>
<template>
  <Modal addClass="modal-series" :showLoader="showLoader">
    <ModalHeaderVod
      ref="header"
      :asset="asset"
      @play="onPlay(asset)"
      @favorite="onFavorite(asset)"
      @score="onChangeScore(asset, $event)"
      :poster="poster()"
      :posterMini="miniPoster()"
    />

    <div class="modal-bottom fade" :class="{ show: showBottom }">
      <div
        v-if="asset.seasons && asset.seasons.length !== 0"
        class="modal-bottom-controls"
      >
        <h6>Todos los episodios de la</h6>
        <SelectModal
          :title="temporadaText(asset.seasons[indexSeason])"
          :list="temporadas()"
          textField="name"
          @select="selectSeason(arguments[1])"
        />
      </div>

      <div class="seasons-wrapper fade" :class="{ show: showBottomContent }">
        <section v-if="!capituloGeneral" class="empty-state">
          <figure>
            <img
              src="../assets/images/empty-illustration.svg"
              width="350"
              height="211"
              alt="Empty Illustration"
            />
          </figure>

          <h1 v-if="asset.seasons" class="h1">
            La temporada no tiene episodios disponibles.
          </h1>
          <h1 v-else class="h1">La serie no tiene contenido disponible.</h1>
        </section>

        <ol v-else class="list-group list-group-flush list-seasons">
          <template v-for="(cap, indexChapter) in capitulos">
            <ChapterDetail
              :ref="'episodio-' + cap.idAsset"
              :key="cap.idAsset"
              :data="cap"
              :isPlaying="isPlaying(cap.idAsset)"
              @play="onPlay(cap)"
              @pause="onPause(cap)"
              @favorite="onFavorite(cap)"
              @score="onChangeScore(cap, $event)"
            />
          </template>
        </ol>
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "modalSeries",

  inject: ["getPlayer"],

  components: {
    Modal: () => import("@/components/Modal.vue"),
    ModalHeaderVod: () => import("@/components/ModalHeaderVod.vue"),
    SelectModal: () => import("@/components/SelectModal.vue"),
    ChapterDetail: () => import("@/components/ChapterDetail.vue"),
  },

  data() {
    return {
      asset: {
        userRating: 0,
        rating: 0,
        favorite: false,
        seasons: [],
      },
      capitulos: [],
      //Episodio que se usa para el rating y favoriteo de la serie completa
      capituloGeneral: null,
      indexSeason: 0,
      showLoader: true,
      showBottom: false,
      showBottomContent: false,
      componentOrigin: undefined,
    };
  },

  mounted() {
    this.$bus.$on("modal-serie:show", this.show);
    this.$bus.$on("player:state-change", this.onPlayerStateChange);
  },

  beforeDestroy() {
    this.$bus.$off("modal-serie:show", this.show);
    this.$bus.$off("player:state-change", this.onPlayerStateChange);
  },

  computed: {
    seasonsAndChapters() {
      if (!this.asset.seasons || this.asset.seasons.length === 0) {
        return "Sin contenido disponible";
      } else if (this.asset.seasons.length === 1) {
        return this.asset.seasons.length + " Temporadas";
      } else {
        return this.asset.seasons.length + " Temporada";
      }
    },
  },

  methods: {
    temporadas() {
      return this.asset.seasons.map(season => {
        return {
          ...season,
          name: this.temporadaText(season)
        }
      })
    },
    temporadaText(season) {
            let textoTemporada = '';
            if(season.capitulos === undefined){
              return season.name;
            }
            if(season.capitulos[0].n_temporada === undefined){
                if(season.name && (season.name.toUpperCase().indexOf('TEMPO') > -1 || season.name.toUpperCase().indexOf('T ') > -1) ){
                    textoTemporada = season.name;
                } else if(season.name) {
                    textoTemporada = `Temporada ${season.name}`;
                } else {
                    textoTemporada = `Temporada ${season.n_temporada}`;
                }
            } else {
                textoTemporada = `Temporada ${season.capitulos[0].n_temporada}`;
            }
            return textoTemporada;
    },
    getCapituloGeneral() {
      if (
        !this.asset.seasons ||
        this.asset.seasons.length === 0 ||
        !this.asset.seasons[0].capitulos ||
        this.asset.seasons[0].capitulos.length === 0
      ) {
        return null;
      }

      return this.asset.seasons[0].capitulos[0];
    },

    poster() {
      return telecentro.tplay.core.api.traerPosterHWSeries(
        this.asset.idAsset,
        360,
        240
      );
    },

    miniPoster() {
      return telecentro.tplay.core.api.traerPosterHWSeries(
        this.asset.idAsset,
        1,
        1
      );
    },

    //Devuelve el episodio que tenga el máximo de edad (campo csasname)
    capituloMaxAge(capitulos) {
      let maxAge = -1;
      let maxAgeCap = null;

      for (let c = 0, cl = capitulos.length; c < cl; c++) {
        let age = parseInt(capitulos[c].csasname);

        if (age > maxAge) {
          maxAge = age;
          maxAgeCap = capitulos[c];
        }
      }

      return maxAgeCap;
    },

    show(obj) {
      const self = this;

      //Reseteo data a sus valores iniciales.
      //Importante para no pisar userRating (así sigue siendo reactivo).
      tplay.resetVueData(self);

      //Asigno con Object.assign para no perder la reactividad en las subpropiedades de asset.
      Object.assign(self.asset, obj.data);

      self.asset.title = telecentro.tplay.core.helpers.string.replaceUnicode(
        self.asset.title
      );
      self.asset.synopsis = telecentro.tplay.core.helpers.string.replaceUnicode(
        self.asset.synopsis
      );

      telecentro.tplay.core.helpers.join(
        __.partial(showModal, ".modal-series"), //Muestro el modal
        function (done) {
          //Cargo datos iniciales

          for (let i = 0; i < self.asset.seasons.length; i++) {
              // eslint-disable-next-line no-loop-func
              telecentro.tplay.core.series.traerCapitulos(self.asset, i, (assetSerie) => {
                  self.asset = assetSerie;
              });
          }

          //Cargo la primera temporada
          self.selectSeason(0, function () {
            if (self.capituloGeneral) {
              //Como la serie no tiene un rango de edad, uso el rango máximo de todos los episodios de la temporada actual:
              const maxAgeCap = self.capituloMaxAge(
                self.asset.seasons[self.indexSeason].capitulos
              );

              self.asset.certificate = maxAgeCap.certificate;
              self.asset.certificateCode = maxAgeCap.certificateCode;

              //Uso el primer episodio para el rating y el favoriteado de la serie.
              //No bloqueo la interfaz para esto.
              telecentro.tplay.core.vod.obtenerScore(
                self.capituloGeneral,
                function () {
                  self.asset.favorite = self.capituloGeneral.favorite;
                  self.asset.rating = self.capituloGeneral.rating;
                  self.asset.userRating = self.capituloGeneral.userRating;
                }
              );
            }

            done();
          });
        }
      )(function () {
        if (gtag) {
          gtag("event", "Detalle serie", {
            event_category: "MODAL-SERIES",
            event_action: "Detalle serie",
            event_label: self.asset.title,
            value: "0",
          });
        }

        self.showLoader = false;

        self.componentOrigin = obj.componentOrigin;

        //Muestro aunque no haya episodios
        setTimeout(function () {
          self.showBottomContent = true;
          self.showBottom = true;
        }, 100);
      });
    },

    onSelectSeason(indexSeason) {
      const self = this;

      self.showBottomContent = false;

      self.selectSeason(indexSeason, function () {
        self.showBottomContent = true;
      });
    },

    selectSeason(indexSeason, onFinish) {
      const self = this;

      self.indexSeason = indexSeason;

      // Traigo los episodios de la temporada.
      //El assetSerie devuelto por traerCapitulos es el mismo objeto (la misma referencia) que el que se le pasa.
      telecentro.tplay.core.series.traerCapitulos(
        self.asset,
        indexSeason,
        function (assetSerie) {
          //Asigno con Object.assign para no perder la reactividad en las subpropiedades de asset.
          //En este caso es innecesaria la asignación, porque debería ser la misma referencia,
          //pero por las dudas de que esa condición cambie en el futuro.
          Object.assign(self.asset, assetSerie);

          const season = self.asset.seasons[indexSeason];

          if (!season) return;

          const r = telecentro.tplay.core.helpers.string.replaceUnicode;
          for (let cap of season.capitulos) {
            cap.title = r(cap.title);
            cap.synopsis = r(cap.synopsis);
          }

          self.capitulos = season.capitulos;
          self.capituloGeneral = self.getCapituloGeneral();

          if (self.capituloGeneral) {
            //Seteo el seekTime de los episodios en paralelo, para no demorar la carga
            telecentro.tplay.core.vod.refreshSeekTime(season.capitulos);
          }

          if (typeof onFinish === "function") {
            onFinish();
          }
        }
      );
    },

    onFavorite(asset) {
      const self = this;
      const favorite = !asset.favorite;

      if (asset === self.asset) {
        //Estoy favoriteando la serie. Uso el capituloGeneral:
        asset = self.capituloGeneral;
      }

      const after = function () {
        if (asset === self.capituloGeneral) {
          //Actualizo el asset de la serie:
          self.asset.favorite = favorite;
        }

        asset.favorite = favorite;
      };

      if (favorite) {
        telecentro.tplay.core.favoritos.agregarFavoritos(asset, after);
      } else {
        telecentro.tplay.core.favoritos.quitarFavoritos(asset, after);
      }
    },

    onPause(asset) {
      this.onPlay(asset);
    },

    onPlay(asset) {
      const player = this.getPlayer();

      if (player.isActive() && player.isAsset(asset.idAsset)) {
        player.onControlPlayPause();
      } else {
        if (gtag) {
          gtag("event", "Reproducir desde modal", {
            event_category: "MODAL-SERIES",
            event_action: "Reproducir desde modal",
            event_label: this.asset.title,
            value: "0",
          });
        }

        asset.serie = this.asset;
        this.$bus.$emit("player:set-source", asset);
        tplay.sendAudience(this.asset, this.componentOrigin);
      }
      let butttonClose = document.querySelector(".close");
      butttonClose?.click();
    },

    onChangeScore(capitulo, userRating) {
      const self = this;

      if (capitulo === self.asset) {
        //Estoy votando la serie. Uso el capituloGeneral:
        capitulo = self.capituloGeneral;
      }

      telecentro.tplay.core.vod.cambiarScoreAssetSeries(
        capitulo.idAsset,
        userRating,
        function (rating) {
          capitulo.rating = rating;
          capitulo.userRating = userRating;

          //Si es el episodio general, actualizo el rating general
          if (capitulo === self.capituloGeneral) {
            self.asset.rating = rating;
            self.asset.userRating = userRating;
          }

          self.$forceUpdate();

          //TODO: evitar usar refs y conseguir que funcione reactivamente el userRating sobre el primer episodio
          self.$refs["episodio-" + capitulo.idAsset][0].$forceUpdate();
        },
        function (err) {
          self.$bus.$emit(
            "show-toast",
            "error",
            "No se pudo obtener el rating del episodio."
          );
        }
      );
    },

    convertToPercent(seekTime, duration) {
      return seekTime ? (seekTime * 100) / duration : 0;
    },

    isPlaying(idAsset) {
      const player = this.getPlayer();

      if (player.isActive() && player.isAsset(idAsset)) {
        return player.isPlaying();
      }

      return false;
    },

    onPlayerStateChange(state, e) {
      var self = this;

      if (self.capitulos && self.asset.seasons) {
        for (let i = 0; i < self.capitulos.length; i++) {
          if (e.idAsset == self.capitulos[i].idAsset) {
            //Actualizo el seek time del episodio
            self.capitulos[i].seekTime = e.seekTime;
            return;
          }
        }
      }

      self.$forceUpdate(); //Importante!
    },
  },
};
</script>
